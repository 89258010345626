<template>
  <div class="bg-v3-purple rounded-full h-fit bg-opacity-20">
      <loading v-if="!links"></loading>
      <template v-else>
    <div class="h-full p-2">
      <nav class="flex flex-1 flex-col w-min h-full" aria-label="Sidebar">
        <ul role="list" class="space-y-1 flex flex-col justify-between h-full">
          <div>
<!--            <navigation-link name="HR Dash" :is-active="isCurrentPage('/hr-dash')" :open="panelOpen" href="/hr-dash"  activeClass="text-v3-purple">-->
<!--              <template v-slot:icon>-->
<!--                <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"-->
<!--                     viewBox="0 0 24 24">-->
<!--                  <path fill="currentColor" d="M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3z"/>-->
<!--                </svg>-->
<!--              </template>-->
<!--            </navigation-link>-->
              <navigation-link name="Account" v-if="hasLink('admin.client')" :is-active="isCurrentPage('/account')" :open="panelOpen" href="/account" activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M12.8742 7V3H2.91602V21H22.8323V7H12.8742ZM6.89928 19H4.90765V17H6.89928V19ZM6.89928 15H4.90765V13H6.89928V15ZM6.89928 11H4.90765V9H6.89928V11ZM6.89928 7H4.90765V5H6.89928V7ZM10.8825 19H8.89091V17H10.8825V19ZM10.8825 15H8.89091V13H10.8825V15ZM10.8825 11H8.89091V9H10.8825V11ZM10.8825 7H8.89091V5H10.8825V7ZM20.8407 19H12.8742V17H14.8658V15H12.8742V13H14.8658V11H12.8742V9H20.8407V19ZM18.8491 11H16.8574V13H18.8491V11ZM18.8491 15H16.8574V17H18.8491V15Z"
                              fill="currentColor"/>
                      </svg>
                  </template>
              </navigation-link>
            <navigation-link name="People" v-if="hasLink('admin.client.users')" :is-active="isCurrentPage('/hr-people')" :open="panelOpen" href="/hr-people" activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                     viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M16 17v2H2v-2s0-4 7-4s7 4 7 4m-3.5-9.5A3.5 3.5 0 1 0 9 11a3.5 3.5 0 0 0 3.5-3.5m3.44 5.5A5.32 5.32 0 0 1 18 17v2h4v-2s0-3.63-6.06-4M15 4a3.4 3.4 0 0 0-1.93.59a5 5 0 0 1 0 5.82A3.4 3.4 0 0 0 15 11a3.5 3.5 0 0 0 0-7"/>
                </svg>
              </template>
            </navigation-link>
              <navigation-link v-if="hasLink('admin.pending-users')" name="Onboarding" :href="getLinkHref('admin.pending-users')" :isActive="isCurrentPage(getLinkHref('admin.pending-users'))" :open="panelOpen" activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M17 3h-3v3h-4V3H7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-5 5a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m4 8H8v-1c0-1.33 2.67-2 4-2s4 .67 4 2zM13 5h-2V1h2zm3 14H8v-1h8zm-4 2H8v-1h4z"/></svg>
                  </template>
              </navigation-link>
              <navigation-link name="Projects" v-if="hasLink('admin.client')" :is-active="isCurrentPage('/base-projects')" :open="panelOpen" href="/base-projects" activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
                  <template v-slot:icon>
                      <svg class="h-6 w-6 shrink-0 fill-current" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.7656 2.625H3.51562C3.10078 2.625 2.76562 2.96016 2.76562 3.375V20.625C2.76562 21.0398 3.10078 21.375 3.51562 21.375H20.7656C21.1805 21.375 21.5156 21.0398 21.5156 20.625V3.375C21.5156 2.96016 21.1805 2.625 20.7656 2.625ZM8.76562 17.4375C8.76562 17.5406 8.68125 17.625 8.57812 17.625H6.70312C6.6 17.625 6.51562 17.5406 6.51562 17.4375V6.5625C6.51562 6.45937 6.6 6.375 6.70312 6.375H8.57812C8.68125 6.375 8.76562 6.45937 8.76562 6.5625V17.4375ZM13.2656 10.875C13.2656 10.9781 13.1813 11.0625 13.0781 11.0625H11.2031C11.1 11.0625 11.0156 10.9781 11.0156 10.875V6.5625C11.0156 6.45937 11.1 6.375 11.2031 6.375H13.0781C13.1813 6.375 13.2656 6.45937 13.2656 6.5625V10.875ZM17.7656 12.5625C17.7656 12.6656 17.6813 12.75 17.5781 12.75H15.7031C15.6 12.75 15.5156 12.6656 15.5156 12.5625V6.5625C15.5156 6.45937 15.6 6.375 15.7031 6.375H17.5781C17.6813 6.375 17.7656 6.45937 17.7656 6.5625V12.5625Z" fill="currentColor"/>
                      </svg>

                  </template>
              </navigation-link>
            <navigation-link name="Attendance" href="/hr-attendance" :open="panelOpen" :isActive="isCurrentPage('/hr-attendance') || isCurrentPage('/hr-weekly')"  activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                     viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="m21.1 12.5l1.4 1.41l-6.53 6.59L12.5 17l1.4-1.41l2.07 2.08zM10 17l3 3H3v-2c0-2.21 3.58-4 8-4l1.89.11zm1-13a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4"/>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="hasLink('holiday-requests')" name="Leave Requests" :open="panelOpen" href="holiday-requests" :isActive="isCurrentPage('/holiday-requests')"  activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M20.8407 3H4.90765C3.81225 3 2.91602 3.9 2.91602 5V19C2.91602 20.1 3.81225 21 4.90765 21H20.8407C21.9361 21 22.8323 20.1 22.8323 19V5C22.8323 3.9 21.9361 3 20.8407 3ZM10.8825 17H5.90346V15H10.8825V17ZM10.8825 13H5.90346V11H10.8825V13ZM10.8825 9H5.90346V7H10.8825V9ZM15.6824 15L12.8742 12.16L14.2783 10.75L15.6824 12.17L18.8391 9L20.2532 10.42L15.6824 15Z"
                        fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="false" name="Benefits" :open="panelOpen">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1411_6018)">
                    <path
                        d="M15.987 2L17.208 4.89214L19.8633 6.22215L17.208 7.55215L15.987 10.4443L14.7659 7.55215L12.1107 6.22215L14.7659 4.89214L15.987 2ZM2.92432 22H6.73382V10.5893H2.92432V22ZM21.0193 16.8131H14.3528L12.3624 16.0558L12.6769 15.0807L14.3533 15.7757H17.039C17.6579 15.7757 18.1628 15.2258 18.1628 14.5516C18.1628 14.0433 17.8674 13.5869 17.4293 13.4003L10.5151 10.5893H8.6388V19.9463L15.3053 22.0005L22.9243 18.8886C22.9148 17.7477 22.0768 16.8136 21.0193 16.8136"
                        fill="currentColor"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1411_6018">
                      <rect width="20" height="20" fill="white" transform="translate(2.92432 2)"/>
                    </clipPath>
                  </defs>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="false" name="Performance" :open="panelOpen">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M14.9253 9.94L12.8739 9L14.9253 8.06L15.8613 6L16.7974 8.06L18.8488 9L16.7974 9.94L15.8613 12L14.9253 9.94ZM4.90737 14L5.84344 11.94L7.89482 11L5.84344 10.06L4.90737 8L3.9713 10.06L1.91992 11L3.9713 11.94L4.90737 14ZM9.38854 9L10.474 6.59L12.8739 5.5L10.474 4.41L9.38854 2L8.3031 4.41L5.90319 5.5L8.3031 6.59L9.38854 9ZM5.40528 20.5L11.3802 14.49L15.3634 18.49L23.8279 8.93L22.4238 7.52L15.3634 15.49L11.3802 11.49L3.91155 19L5.40528 20.5Z"
                      fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="hasLink('current-user')" :is-active="isCurrentPage('/documents')" href="/documents" name="Documents" :open="panelOpen"  activeClass="text-v3-white-100 bg-v3-purple dark:text-v3-gray-900" additional-classes="text-v3-purple">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M16.7379 10.5L18.3512 12.12V15.5H15.3637V10.5H16.7379ZM22.8323 8V18C22.8323 19.1 21.9361 20 20.8407 20H4.90765C3.81225 20 2.91602 19.1 2.91602 18L2.92597 6C2.92597 4.9 3.81225 4 4.90765 4H10.8825L12.8742 6H20.8407C21.9361 6 22.8323 6.9 22.8323 8ZM19.8449 11.5L17.3553 9H13.87V17H19.8449V11.5Z"
                      fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="false" name="Reports" :open="panelOpen">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.8742 8V2H6.89935C6.37113 2 5.86455 2.21071 5.49105 2.58579C5.11755 2.96086 4.90771 3.46957 4.90771 4V20C4.90771 20.5304 5.11755 21.0391 5.49105 21.4142C5.86455 21.7893 6.37113 22 6.89935 22H18.8491C19.3774 22 19.8839 21.7893 20.2574 21.4142C20.6309 21.0391 20.8408 20.5304 20.8408 20V10H14.8659C14.3377 10 13.8311 9.78929 13.4576 9.41421C13.0841 9.03914 12.8742 8.53043 12.8742 8ZM12.8742 15C13.0723 15 13.2623 15.079 13.4024 15.2197C13.5424 15.3603 13.6211 15.5511 13.6211 15.75V18.25C13.6211 18.4489 13.5424 18.6397 13.4024 18.7803C13.2623 18.921 13.0723 19 12.8742 19C12.6762 19 12.4862 18.921 12.3461 18.7803C12.2061 18.6397 12.1274 18.4489 12.1274 18.25V15.75C12.1274 15.5511 12.2061 15.3603 12.3461 15.2197C12.4862 15.079 12.6762 15 12.8742 15ZM9.63784 11C9.83592 11 10.0259 11.079 10.166 11.2197C10.306 11.3603 10.3847 11.5511 10.3847 11.75V18.25C10.3847 18.4489 10.306 18.6397 10.166 18.7803C10.0259 18.921 9.83592 19 9.63784 19C9.43976 19 9.24979 18.921 9.10973 18.7803C8.96967 18.6397 8.89098 18.4489 8.89098 18.25V11.75C8.89098 11.5511 8.96967 11.3603 9.10973 11.2197C9.24979 11.079 9.43976 11 9.63784 11ZM16.1106 13C16.3087 13 16.4987 13.079 16.6388 13.2197C16.7788 13.3603 16.8575 13.5511 16.8575 13.75V18.25C16.8575 18.4489 16.7788 18.6397 16.6388 18.7803C16.4987 18.921 16.3087 19 16.1106 19C15.9126 19 15.7226 18.921 15.5825 18.7803C15.4425 18.6397 15.3638 18.4489 15.3638 18.25V13.75C15.3638 13.5511 15.4425 13.3603 15.5825 13.2197C15.7226 13.079 15.9126 13 16.1106 13ZM14.368 8V2.5L20.3429 8.5H14.8659C14.7338 8.5 14.6072 8.44732 14.5138 8.35355C14.4204 8.25979 14.368 8.13261 14.368 8Z"
                      fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="false" name="Organisation" :open="panelOpen">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M17.8533 11H20.8407C21.3689 11 21.8755 10.7893 22.249 10.4142C22.6225 10.0391 22.8323 9.53043 22.8323 9V5C22.8323 4.46957 22.6225 3.96086 22.249 3.58579C21.8755 3.21071 21.3689 3 20.8407 3H17.8533C17.325 3 16.8185 3.21071 16.445 3.58579C16.0715 3.96086 15.8616 4.46957 15.8616 5V6H9.89669V5C9.89669 4.46957 9.68685 3.96086 9.31335 3.58579C8.93985 3.21071 8.43327 3 7.90505 3H4.90765C3.81225 3 2.91602 3.9 2.91602 5V9C2.91602 9.53043 3.12585 10.0391 3.49935 10.4142C3.87285 10.7893 4.37943 11 4.90765 11H7.89509C8.42331 11 8.92989 10.7893 9.30339 10.4142C9.67689 10.0391 9.88673 9.53043 9.88673 9V8H11.8784V15.01C11.8784 16.66 13.2128 18 14.8558 18H15.8616V19C15.8616 19.5304 16.0715 20.0391 16.445 20.4142C16.8185 20.7893 17.325 21 17.8533 21H20.8407C21.3689 21 21.8755 20.7893 22.249 20.4142C22.6225 20.0391 22.8323 19.5304 22.8323 19V15C22.8323 14.4696 22.6225 13.9609 22.249 13.5858C21.8755 13.2107 21.3689 13 20.8407 13H17.8533C17.325 13 16.8185 13.2107 16.445 13.5858C16.0715 13.9609 15.8616 14.4696 15.8616 15V16H14.8558C14.3181 16 13.87 15.55 13.87 15.01V8H15.8616V9C15.8616 10.1 16.7579 11 17.8533 11Z"
                      fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
            <navigation-link v-if="false" name="Permissions" :open="panelOpen">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M18.8491 11C19.5462 11 20.2134 11.1 20.8408 11.29V10C20.8408 8.9 19.9445 8 18.8491 8H17.8533V6C17.8533 3.24 15.6227 1 12.8742 1C10.1258 1 7.89516 3.24 7.89516 6V8H6.89935C5.80395 8 4.90771 8.9 4.90771 10V20C4.90771 21.1 5.80395 22 6.89935 22H13.1332C12.4037 20.9511 11.9747 19.7214 11.8928 18.4447C11.8109 17.168 12.0792 15.8932 12.6686 14.759C13.258 13.6247 14.1459 12.6746 15.2356 12.0118C16.3253 11.349 17.5751 10.9991 18.8491 11ZM9.78721 6C9.78721 4.29 11.1714 2.9 12.8742 2.9C14.5771 2.9 15.9613 4.29 15.9613 6V8H9.78721V6Z"
                      fill="currentColor"/>
                  <path
                      d="M18.8492 13C16.1007 13 13.8701 15.24 13.8701 18C13.8701 20.76 16.1007 23 18.8492 23C21.5976 23 23.8283 20.76 23.8283 18C23.8283 15.24 21.5976 13 18.8492 13ZM18.8492 15C19.6757 15 20.3429 15.67 20.3429 16.5C20.3429 17.33 19.6757 18 18.8492 18C18.0227 18 17.3555 17.33 17.3555 16.5C17.3555 15.67 18.0227 15 18.8492 15ZM18.8492 21C17.8235 21 16.9173 20.48 16.3796 19.68C17.1065 19.26 17.943 19 18.8492 19C19.7554 19 20.5919 19.26 21.3188 19.68C20.7811 20.48 19.8749 21 18.8492 21Z"
                      fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
          </div>
          <div class="pt-12">
            <navigation-link name="My Dash" :open="panelOpen" href="/" active-class="text-v3-purple" additional-classes="text-v3-purple ring-2 ring-current">
              <template v-slot:icon>
                <svg class="h-6 w-6 shrink-0" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5 21V9L12.5 3L20.5 9V21H14.5V14H10.5V21H4.5Z" fill="currentColor"/>
                </svg>
              </template>
            </navigation-link>
          </div>
        </ul>
      </nav>
    </div>
      </template>
  </div>
</template>
<script>
import NavigationLink from "@/v3/components/navigation/NavigationLink.vue"
import Loading from "@/components/Loading.vue";

export default {
  name: 'Hr-navigation',
  components: {NavigationLink, Loading},
    data(){
      return {
          panelOpen: false
      };
    },
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    mounted(){
      const storageSetting = window.localStorage.getItem('navigationPanelOpen');
      if(storageSetting!== null){
          this.panelOpen = storageSetting === "true";
      }
    },
    methods:{
      isCurrentPage(url)
      {
          return this.$route.path.includes(url)
      },
        togglePanel(){
          this.panelOpen = !this.panelOpen;
          window.localStorage.setItem('navigationPanelOpen', this.panelOpen);
        },
        hasLink(key){
            if(this.links) {
                return this.links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        getLinkHref(key){
            return this.getFrontendRouteWithoutHostFromApiRoute(this.links.filter(link => link.rels.contains(key)).first().href);
        },
    },

}
</script>
